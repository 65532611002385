import { ApiResponse } from '../models/ApiResponse';
import { FormConfig, formTemplateCache, FormTemplatePagedFilter, GetAllTemplatesRequest } from '../models/Form';
import BaseService from './BaseService';

abstract class TemplateFormServiceBase extends BaseService {
  protected static create(url: string, data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return this.post(url, data);
  }

  protected static update(url: string, data: Record<string, unknown>): Promise<ApiResponse<FormConfig>> {
    return this.put(url, data);
  }

  protected static archive(url: string): Promise<ApiResponse<boolean>> {
    return this.put(url);
  }

  protected static restore(url: string): Promise<ApiResponse<boolean>> {
    return this.put(url);
  }

  protected static getAll(url: string, request: GetAllTemplatesRequest): Promise<ApiResponse<FormConfig[]>> {
    const cacheKey = { endpoint: url, filter: request };
    const cachedItem = formTemplateCache.get(cacheKey);
    if (cachedItem) {
      return cachedItem;
    }

    const promise = this.get<FormConfig[]>(url, { params: request });
    formTemplateCache.put(cacheKey, promise);
    return promise;
  }

  protected static getAllPaged(url: string, filter: FormTemplatePagedFilter, fromCache = true): Promise<ApiResponse<FormConfig[]>> {
    const cacheKey = { endpoint: url, filter };
    const cachedItem = formTemplateCache.get(cacheKey);
    if (cachedItem && fromCache) {
      return Promise.resolve(cachedItem);
    }

    const promise = this.get<FormConfig[]>(url, { params: { ...filter } });
    formTemplateCache.put(cacheKey, promise);
    return promise;
  }

  protected static getById(
    url: string,
    formId: string,
    version: number | undefined = undefined,
    excludeSections: boolean | undefined = undefined,
  ): Promise<ApiResponse<FormConfig>> {
    const cacheKey = { endpoint: url, filter: { formId, version, excludeSections } };
    const cachedItem = formTemplateCache.get(cacheKey);
    if (cachedItem) {
      return Promise.resolve(cachedItem);
    }

    const promise = this.get<FormConfig>(url, { params: { version, excludeSections } });
    formTemplateCache.put(cacheKey, promise);
    return promise;
  }
}

export default TemplateFormServiceBase;
